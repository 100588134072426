import React from "react";
// import schoolLogo from "../img/products/school.png";
// import tnpcLogo from "../img/products/tnpc.jpg";
// import crpfLogo from "../img/products/crpf.jpeg";
// import "../styles/products.css";
import "../styles/carrier.css";
import mithra from "../img/products/mithra.png";

export const Products = ({ border }) => {
  // const products = [
  //   {
  //     title: "KST School",
  //     description: "Aims to provide extensive hands-on work experience for graduates.",
  //     link: "#/",
  //     logo: schoolLogo,
  //   },
  //   {
  //     title: "Attendance",
  //     description: "Aims to provide extensive hands-on work experience for graduates.",
  //     link: "#/",
  //     logo: schoolLogo,
  //   },
  //   {
  //     title: "TNPC",
  //     description: "Tamilnadu Police Welfare Canteen Management Software",
  //     link: "https://www.tnpolicecanteen.com/",
  //     logo: tnpcLogo,
  //   },
  //   {
  //     title: "CRPF",
  //     description: "Kendriya Police Kalyan Bhandar (KPKB) at GC CRPF Avadi",
  //     link: "https://kpkbgcavadi.in/",
  //     logo: crpfLogo,
  //   },
  // ];
  return (
    <div className="carr_div">
      <img alt="post" loading="lazy" className="carr_post" src={mithra}></img>
      {/* <img alt="post" loading='lazy' className="carr_post" src={c_2}></img> */}
    </div>
    // <>
    //   <h1 data-aos="fade-up" data-aos-duration="1000" className={border ? "about_head rounded-lg shadow-md border text-gray-800 border-b border-gray-200 rounded-t-lg bg-gray-50" : "products_head"}>
    //     Products
    //   </h1>
    //   <div className="products_div" data-aos="fade-up" data-aos-duration="1000">
    //     {products.map((data, index) => (
    //       <div key={index} className="products">
    //         <div className="products-image">
    //           <img src={data.logo} alt={data.title} />
    //         </div>
    //         <div className="products-title">{data.title}</div>
    //         <div className="products-desc">{data.description}</div>
    //         {/* <a href={data.link} rel="noreferrer" target="_blank" title={data.title}>
    //           <div className="products-button">EXPLORE</div>
    //         </a> */}
    //       </div>
    //     ))}
    //   </div>
    // </>
  );
};
