import React from "react";
import "../styles/casestudy.css";
import case1 from "../casestudy/case1.json";
const CaseStudy = () => {
  return (
    <>
      {/* <h1 className="team_head rounded-lg shadow-md border text-gray-800 border-b border-gray-200 rounded-t-lg bg-gray-50">Case Study</h1> */}
      <div>
        <div className="title_div">
          <div className="invert">{case1.title}</div>
        </div>
        <div className="body_div">
          {case1.data.map((value, index) => (
            <div className="data_div">
              <div className="head_div">
                <span className="text-gray-800">0{index + 1}</span> / {value.head}
              </div>
              <div className="content_div" dangerouslySetInnerHTML={{ __html: value.content }}></div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CaseStudy;
