import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useRef } from "react";
import CaseStudy from "./component/CaseStudy";
import { Products } from "./component/Products";

const Nav = React.lazy(() => import(/* webpackPrefetch: true */ "./component/Nav"));
const Footer = React.lazy(() => import(/* webpackPrefetch: true */ "./component/Footer"));
const About = React.lazy(() => import(/* webpackPrefetch: true */ "./component/About"));
// const Team = React.lazy(() => import(/* webpackPrefetch: true */ "./component/Team"));
const Project = React.lazy(() => import(/* webpackPrefetch: true */ "./component/Project"));
const Contact = React.lazy(() => import(/* webpackPrefetch: true */ "./component/Contact"));
const Gallery = React.lazy(() => import(/* webpackPrefetch: true */ "./component/Gallery"));
const Carrier = React.lazy(() => import(/* webpackPrefetch: true */ "./component/Carrier"));
const ServiceNavView = React.lazy(() => import(/* webpackPrefetch: true */ "./component/ServiceNavView"));
const Privacy = React.lazy(() => import(/* webpackPrefetch: true */ "./component/Privacy"));
const Terms = React.lazy(() => import(/* webpackPrefetch: true */ "./component/Terms"));
const Main = React.lazy(() => import(/* webpackPrefetch: true */ "./component/Main"));

function App() {
  const homeRef = useRef();
  function homeFun() {
    homeRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="App">
      <header className="App-header">
        <React.Suspense
          fallback={
            <div className="loader">
              <div className="loaderRectangle">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          }
        >
          <BrowserRouter>
            <Nav homeRef={homeRef} />
            <Routes>
              <Route index element={<Main />} />
              <Route path="/about" element={<About />} />
              {/* <Route path="/team" element={<Team teamRef={teamRef} />} /> */}
              <Route path="/project/*" element={<Project />} />
              <Route path="/products" element={<Products border={true} />} />
              <Route path="/contact" element={<Contact border={true} />} />
              <Route path="/casestudy" element={<CaseStudy />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/service" element={<ServiceNavView />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/terms-and-conditions" element={<Terms />} />
              <Route path="/carrier" element={<Carrier />} />
            </Routes>
            <Footer />
            <div data-dial-init className="fixed right-6 bottom-4 group sm:right-4">
              <button type="button" onClick={homeFun} data-dial-toggle="speed-dial-menu-default" aria-controls="speed-dial-menu-default" aria-expanded="false" className="flex justify-center items-center w-11 h-11 up text-white bg-blue-700 rounded-full hover:bg-blue-800 focus:ring-2 focus:ring-blue-300 focus:outline-none">
                <svg className="group-hover:animate-bounce w-6 h-6 up_icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 11l7-7 7 7M5 19l7-7 7 7"></path>
                </svg>
                <span className="sr-only">UP</span>
              </button>
            </div>
          </BrowserRouter>
        </React.Suspense>
      </header>
    </div>
  );
}

export default App;
